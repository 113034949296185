import React, { Component } from "react";
import { Link } from "gatsby";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
          <span>©2021 Jakobsbrunnen - Stein am Rhein. <Link to="/impressum_datenschutz">Impressum & Datenschutz</Link>.</span>
        </div>
      </div>
    );
  }
}
